import Image from 'next/legacy/image';
import React, { ReactElement } from 'react';

import { FeaturedBlogsCarousel } from '@components/Carousels/FeaturedBlogsCarousel';
import { NewsletterSubscriptionForm } from '@components/NewsletterSubForm/NewsletterSubForm';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';

export function DynamicInternal(): ReactElement {
  return (
    <>
      <section
        className="full-bleed 
          m-0 
          min-h-[450px]
          bg-gradient-to-b from-orange 
          from-0% 
          via-orange-offset via-40% to-orange to-100% px-0 py-4"
      >
        <h2 className="mx-auto max-w-narrowPage px-0 py-4 font-secondary text-5xl font-bold uppercase text-white">
          Popular Posts from the{' '}
          <TextLink
            prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
            href="/blog"
            className="text-inherit decoration-white hover:decoration-white"
          >
            Bulk Blog
          </TextLink>
        </h2>
        <Paragraph className="mx-auto my-0 max-w-narrowPage pb-4 pt-2 text-left text-white">
          With over 700 recipes and articles, the Bulk Nutrients Blog has
          something for everyone! Find a new workout, meet our ambassadors or
          take a deep dive into our products today.
        </Paragraph>
        <FeaturedBlogsCarousel />
      </section>
      <section className="full-bleed bg-grey-dark text-center">
        <TextLink href="/protein-calculator">
          <Image
            className="lazyload max-w-full"
            title="Compare all Bulk Nutrients proteins"
            src="/images/compare-all-bulk-nutrients-proteins.jpg"
            alt="Compare all Bulk Nutrients proteins"
            width={1170}
            height={400}
          />
        </TextLink>
      </section>
      <NewsletterSubscriptionForm source="BN - Site Footer Sign Up" />
    </>
  );
}
