import Image from 'next/image';
import React from 'react';

import { TextLink } from '@components/Typography/TextLink/TextLink';
import { RewardsLink } from '@components/Typography/TextLink/TextLink';

function SocialIcon({ children }: { children: React.ReactNode }) {
  return (
    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-grey-dark bg-center bg-no-repeat">
      {children}
    </span>
  );
}

export function StaticFooter() {
  return (
    <div
      className="full-bleed bg-grey-light dark:bg-grey-darkest"
      data-testid="footer"
    >
      <div
        className="mx-auto mb-6 mt-0 grid w-[calc(100%_-_2_*_var(--inset-from-edge))] 
      max-w-maxWidthMenu grid-cols-1 gap-x-[75px] lg:grid-cols-3"
      >
        <div>
          <h3 className="-ml2 mb-0 mr-0 mt-8 inline-block border-b-0 border-l-[15px] border-r-0 border-t-0 border-solid border-l-orange bg-grey-dark pb-0.5 pl-2.5 pr-5 pt-0.5 font-secondary text-3xl font-normal uppercase text-white">
            Information
          </h3>
          <ul className="my-4 list-disc pl-10">
            <li className="my-2 text-sm">
              <TextLink href="/about-us">About Bulk Nutrients</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/categories/shop-by-recommended">
                Ambassadors
              </TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/careers">Careers @ Bulk</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/community-support">Community Support</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/contact-us">Contact Us</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/free-samples">Free Samples</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/faqs">Frequently Asked Questions</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/giftcertificates">Gift Certificates</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/faqs/hasta-certification">
                HASTA Certification
              </TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/news">Latest News</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/partners">Partners</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/faqs/protein-testing">Protein Testing</TextLink>
            </li>
            <li className="my-2 text-sm">
              <RewardsLink>Rewards</RewardsLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/bulk-nutrients-social-hub">Social Hub</TextLink>
            </li>
            <li className="my-2 text-sm">
              <TextLink href="/faqs/shipping">Shipping &amp; Returns</TextLink>
            </li>
          </ul>
        </div>
        <div className="text-grey-dark dark:text-white lg:col-start-2 lg:row-start-1">
          <h3 className="-ml2 mb-0 mr-0 mt-8 inline-block border-b-0 border-l-[15px] border-r-0 border-t-0 border-solid border-l-orange bg-grey-dark pb-0.5 pl-2.5 pr-5 pt-0.5 font-secondary text-3xl font-normal uppercase text-white">
            About Bulk Nutrients
          </h3>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            We're an Australian manufacturer and supplier of high quality sports
            supplements.
          </p>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            Operating since 2008, Bulk Nutrients has become one of the premier
            Australian brands to supply nutritional products to top level
            athletes, competitors and those on a journey to a healthier
            lifestyle.
          </p>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            <TextLink href="/about-us">Find out more about Bulk</TextLink>.
          </p>
        </div>
        <div>
          <h3 className="-ml2 mb-0 mr-0 mt-8 inline-block border-b-0 border-l-[15px] border-r-0 border-t-0 border-solid border-l-orange bg-grey-dark pb-0.5 pl-2.5 pr-5 pt-0.5 font-secondary text-3xl font-normal uppercase text-white">
            Contact Bulk Nutrients
          </h3>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            One thing that sets Bulk Nutrients apart is that we love to talk to
            our customers!
          </p>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            Whether you need product advice, help with the website or need a
            change made to your order... call us on{' '}
            <TextLink href="tel:+61362664725">+61 3 6266 4725</TextLink>.
          </p>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            If you prefer email you can email us day or night at{' '}
            <TextLink href="mailto:info@bulknutrients.com.au">
              info@bulknutrients.com.au
            </TextLink>
          </p>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            For online chat, hit the 'Chat' button in the bottom right hand
            corner of your screen and you'll be connected to one of our lovely
            customer service team.
          </p>
          <p className="mx-0 mb-5 ml-2.5 mt-2.5 text-sm text-grey-dark dark:text-white">
            Or if you'd like to get in touch through our{' '}
            <TextLink href="/contact-us">online contact form</TextLink>, that's
            cool too!
          </p>
        </div>
        <div className="lg:col-start-2 lg:row-start-1 lg:pt-[300px]">
          <h3 className="-ml2 mb-0 mr-0 mt-8 inline-block border-b-0 border-l-[15px] border-r-0 border-t-0 border-solid border-l-orange bg-grey-dark pb-0.5 pl-2.5 pr-5 pt-0.5 font-secondary text-3xl font-normal uppercase text-white">
            Follow us on Social Media
          </h3>
          <div
            data-testid="footer-socials"
            className="mx-0 my-[1em] flex gap-2.5"
          >
            <a
              href="https://www.facebook.com/bulknutrients"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon>
                <Image
                  src="/images/facebook.svg"
                  width={12}
                  height={22}
                  alt="Bulk Nutrients on Facebook"
                />
              </SocialIcon>
            </a>
            <a
              href="https://www.instagram.com/bulknutrients/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon>
                <Image
                  src="/images/instagram.svg"
                  width={19}
                  height={19}
                  alt="Check out Bulk Nutrients' Instagram posts"
                />
              </SocialIcon>
            </a>
            <a
              href="https://www.tiktok.com/@bulk.nutrients"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon>
                <Image
                  src="/images/tiktok.svg"
                  width={38}
                  height={38}
                  alt="View Bulk Nutrients' TikTok content"
                />
              </SocialIcon>
            </a>
            <a
              href="https://www.youtube.com/user/BulkNutrients/feed"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon>
                <Image
                  src="/images/youtube.svg"
                  width={20}
                  height={24}
                  alt="View Bulk Nutrients' YouTube Channel"
                />
              </SocialIcon>
            </a>
          </div>
        </div>
      </div>
      <div className="full-bleed flex flex-col items-center justify-start gap-4">
        <div
          className="mx-auto mb-5 mt-0 w-auto p-2.5 text-center 
        text-base text-grey-dark dark:text-white micro:w-[clamp(320px,640px,100%)]"
        >
          We acknowledge the Traditional Owners of the land on which our
          organisation operates, the Melukerdee People of the South East Nation
          and pay our respects to Elders past, present, and emerging.
        </div>

        <div
          className="mx-auto mb-5 mt-0 w-auto border-b-0 border-l-[15px] border-r-0 border-t-0
        border-solid border-l-orange bg-grey-dark p-2.5 text-center micro:w-[clamp(320px,640px,100%)]"
        >
          <h3 className="m-0 p-0 font-secondary text-4xl uppercase text-white">
            Bulk Nutrients is proudly <br /> Australian owned and operated.
          </h3>
        </div>

        <address
          className="text-center font-primary text-base font-light not-italic 
        text-grey-dark dark:text-white"
        >
          7 Crabtree Road, Grove, Tasmania, 7109.
          <br />
          ABN: 17 158 981 447
        </address>
        <p className="mb-4 flex flex-wrap justify-center gap-x-6 gap-y-2.5 text-center">
          <TextLink href="/bulk-nutrients-terms-conditions">
            Terms &amp; Conditions
          </TextLink>
          <TextLink href="/bulk-nutrients-packaging-sustainability-strategy">
            Sustainability Strategy
          </TextLink>
          <TextLink href="/bulk-nutrients-privacy-policy">
            Privacy Policy
          </TextLink>
          <TextLink href="/payments">Payment Information</TextLink>
          <TextLink href="/sitemap">Sitemap</TextLink>
        </p>
        <div className="pb-10 text-sm text-grey-dark dark:text-white">
          <p className="mb-4 flex flex-wrap justify-center text-center">
            All prices are in Australian dollars (AUD) and include GST unless
            otherwise stated.
            <br />
            All content copyright &copy; Bulk Nutrients 2008 - 2024
          </p>
        </div>
      </div>
    </div>
  );
}
