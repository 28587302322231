import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { KLAVIYO_LISTS } from '@lib/constants';
import { removeUndefined } from '@lib/removeUndefined';
import { log } from '@lib/utils';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let _learnq: any;

const addProfileToBrowser = ({
  email,
  firstName,
  lastName,
  country,
  postcode,
  state,
  suburb,
  address1,
  address2,
  phone,
  company,
}: {
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  postcode: string;
  state: string;
  suburb: string;
  address1: string;
  address2: string;
  phone: string;
  company: string;
}) => {
  const learnq = _learnq || [];
  const kl_email = email;
  const kl_first_name = firstName;
  const kl_last_name = lastName;
  const kl_country = country;
  const kl_postcode = postcode;
  const kl_state = state;
  const kl_city = suburb;
  const kl_address_1 = address1;
  const kl_address_2 = address2;
  const kl_phone = phone;
  const kl_company = company;

  const kl_properties = {
    $first_name: kl_first_name,
    $email: kl_email,
    $last_name: kl_last_name,
    $city: kl_city,
    $region: kl_state,
    $country: kl_country,
    $zip: kl_postcode,
    $address1: kl_address_1,
    $address2: kl_address_2,
  };
  if (typeof kl_company !== 'undefined') {
    kl_properties['Company'] = kl_company;
  }
  if (kl_phone) {
    kl_properties['$phone_number'] = kl_phone;
  }
  learnq.push(['identify', kl_properties]);
};

const newsletterSubscribe = async ({
  email,
  phone,
  firstName,
  lastName,
  company,
  source = 'Create Account form',
}: {
  email: string;
  phone?: string;
  firstName: string;
  lastName?: string;
  company?: string;
  source?: string;
}) => {
  const url = new URL(`/client/subscriptions/`, 'https://a.klaviyo.com');
  url.searchParams.set(
    'company_id',
    process.env.NEXT_PUBLIC_KLAVIYO_API_KEY as string
  );

  const body = {
    data: {
      type: 'subscription',
      attributes: {
        custom_source: source,
        profile: {
          data: {
            type: 'profile',
            attributes: {
              email: email,
              phone_number: phone,
              first_name: firstName,
              last_name: lastName,
              organization: company,
            },
          },
        },
      },
      relationships: {
        list: { data: { type: 'list', id: KLAVIYO_LISTS.NEWSLETTER } },
      },
    },
  };

  const newsletterSignupResponse = await fetch(
    url.origin + url.pathname + url.search,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        revision: '2024-07-15',
      },
      body: JSON.stringify(removeUndefined(body)),
    }
  );

  if (newsletterSignupResponse.ok) {
    return true;
  } else {
    // Log the failure to Sentry. I am imagining we will see this error when user is
    // running ad-blockers and the request to kmail-lists is blocked.
    log(new Error('Klaviyo newsletter list signup failed for user ' + email));
  }
  return false;
};

const smsSubscribe = async ({
  email,
  phone,
  firstName,
  lastName,
  company,
}: {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  company?: string;
}) => {
  const url = new URL(`/client/subscriptions/`, 'https://a.klaviyo.com');
  url.searchParams.set(
    'company_id',
    process.env.NEXT_PUBLIC_KLAVIYO_API_KEY as string
  );

  const body = {
    data: {
      type: 'subscription',
      attributes: {
        custom_source: 'Create Account form',
        profile: {
          data: {
            type: 'profile',
            attributes: {
              email: email,
              phone_number: phone,
              first_name: firstName,
              last_name: lastName,
              organization: company,
            },
          },
        },
      },
      relationships: {
        list: { data: { type: 'list', id: KLAVIYO_LISTS.SMS } },
      },
    },
  };

  const smsSignupResponse = await fetch(
    url.origin + url.pathname + url.search,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        revision: '2024-07-15',
      },
      body: JSON.stringify(removeUndefined(body)),
    }
  );

  if (smsSignupResponse.ok) {
    return true;
  } else {
    // Log the failure to Sentry. I am imagining we will see this error when user is
    // running ad-blockers and the request to kmail-lists is blocked.
    log(
      new Error('Klaviyo sms marketing list signup failed for user ' + email)
    );
  }
  return false;
};

const freeSampleRequestedSubscribe = async ({
  email,
  phone,
  firstName,
  lastName,
  company,
  freeSample,
}: {
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  freeSample: string;
}) => {
  const url = new URL(`/client/subscriptions/`, 'https://a.klaviyo.com');
  url.searchParams.set(
    'company_id',
    process.env.NEXT_PUBLIC_KLAVIYO_API_KEY as string
  );

  const body = {
    data: {
      type: 'subscription',
      attributes: {
        custom_source: 'Create Account form',
        profile: {
          data: {
            type: 'profile',
            attributes: {
              email: email,
              phone_number: phone,
              first_name: firstName,
              last_name: lastName,
              organization: company,
              properties: {
                RequestedSamplePreferred: freeSample,
                RequestedSample: new Date().toISOString(),
              },
            },
          },
        },
      },
      relationships: {
        list: {
          data: { type: 'list', id: KLAVIYO_LISTS.FREE_SAMPLE_REQUESTED },
        },
      },
    },
  };

  const freeSampleSignupResponse = await fetch(
    url.origin + url.pathname + url.search,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        revision: '2024-07-15',
      },
      body: JSON.stringify(removeUndefined(body)),
    }
  );

  if (freeSampleSignupResponse.ok) {
    return true;
  } else {
    // Log the failure to Sentry. I am imagining we will see this error when user is
    // running ad-blockers and the request to kmail-lists is blocked.
    log(
      new Error(
        'Klaviyo free sample requested list signup failed for user ' + email
      )
    );
  }
  return false;
};

// Hook
export function useKlaviyo() {
  return {
    addProfileToBrowser,
    newsletterSubscribe,
    smsSubscribe,
    freeSampleRequestedSubscribe,
  };
}
