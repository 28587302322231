import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@components/Button';
import { ButtonStyle, ButtonType } from '@components/Button/Button';
import { Input } from '@components/FormComponents';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { useKlaviyo } from '@hooks/useKlaviyo';
import { inputTypes, NewsletterSubscriptionFields } from '@interfaces/IForms';

const messages = {
  ERROR: {
    header: 'Oh no...',
    msg: 'Something went wrong, please try again later!',
  },
  SUCCESS: {
    header: 'Awesome Work!',
    msg: "You're on the list and will receive our next weekly newsletter, score :D",
  },
};

interface Message {
  header: string;
  msg: string;
}

export function NewsletterSubscriptionForm({
  source,
}: {
  source: string;
}): ReactElement {
  const { newsletterSubscribe } = useKlaviyo();
  const router = useRouter();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [msgToDisplay, setMsgToDisplay] = useState<Message>(messages.SUCCESS);

  const rhfMethods = useForm<NewsletterSubscriptionFields>({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      email: '',
    },
  });

  const handleSubscription = async ({
    firstName,
    email,
  }: NewsletterSubscriptionFields) => {
    const success = await newsletterSubscribe({
      firstName,
      email,
      source,
    });

    setSubmitted(true);
    setMsgToDisplay(success ? messages.SUCCESS : messages.ERROR);
  };

  useEffect(() => {
    // reset when the page changes
    // watching router to especially catch a user going blog -> blog
    setSubmitted(false);
    rhfMethods.reset({
      firstName: '',
      email: '',
    });
  }, [router]);

  return (
    <section
      data-section-type="newsletterSubscription"
      className="full-bleed bg-black p-8 text-white"
    >
      {!submitted && (
        <FormProvider {...rhfMethods}>
          <form
            onSubmit={rhfMethods.handleSubmit(handleSubscription)}
            className="mx-auto max-w-[780px] pb-5"
          >
            <h3 className="py-5 font-bold text-orange">
              Sign up for the Bulk Nutrients newsletter
            </h3>

            <Input
              type={inputTypes.text}
              label=""
              name="firstName"
              placeholder="First Name"
              required
            />
            <Input
              type={inputTypes.email}
              label=""
              name="email"
              placeholder="Email"
              required
            />
            <div className="btn-nl-subscribe">
              <Button
                type={ButtonType.submit}
                buttonStyle={ButtonStyle.primary}
              >
                Subscribe
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      {submitted && (
        // large padding to prevent page shifting to a smaller component
        <div className="p-[71px] text-center">
          <h2 className="pt-4 font-bold text-orange">{msgToDisplay.header}</h2>
          <Paragraph>{msgToDisplay.msg}</Paragraph>
        </div>
      )}
    </section>
  );
}
